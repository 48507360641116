// Generated by Framer (401b244)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yVtQ6RrJM", "RslWABX15", "e3CFiUFON"];

const serializationHash = "framer-S4Lod"

const variantClassNames = {e3CFiUFON: "framer-v-1usw2pu", RslWABX15: "framer-v-1xoomlz", yVtQ6RrJM: "framer-v-uj21xf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "yVtQ6RrJM", iPad: "RslWABX15", Mobile: "e3CFiUFON"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yVtQ6RrJM"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yVtQ6RrJM", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/KbS3n7eEQsnqZqX8Bq01WaX76g.png", srcSet: "https://framerusercontent.com/images/KbS3n7eEQsnqZqX8Bq01WaX76g.png?scale-down-to=512 512w,https://framerusercontent.com/images/KbS3n7eEQsnqZqX8Bq01WaX76g.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/KbS3n7eEQsnqZqX8Bq01WaX76g.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/KbS3n7eEQsnqZqX8Bq01WaX76g.png 4096w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uj21xf", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"yVtQ6RrJM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({e3CFiUFON: {"data-framer-name": "Mobile"}, RslWABX15: {"data-framer-name": "iPad"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S4Lod.framer-158z1ph, .framer-S4Lod .framer-158z1ph { display: block; }", ".framer-S4Lod.framer-uj21xf { height: 800px; position: relative; width: 1440px; }", ".framer-S4Lod.framer-v-1xoomlz.framer-uj21xf { aspect-ratio: 1.8 / 1; height: var(--framer-aspect-ratio-supported, 450px); width: 810px; }", ".framer-S4Lod.framer-v-1usw2pu.framer-uj21xf { aspect-ratio: 1.8 / 1; height: var(--framer-aspect-ratio-supported, 217px); width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"RslWABX15":{"layout":["fixed","fixed"]},"e3CFiUFON":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQYzDtJTVF: React.ComponentType<Props> = withCSS(Component, css, "framer-S4Lod") as typeof Component;
export default FramerQYzDtJTVF;

FramerQYzDtJTVF.displayName = "Cover Picture";

FramerQYzDtJTVF.defaultProps = {height: 800, width: 1440};

addPropertyControls(FramerQYzDtJTVF, {variant: {options: ["yVtQ6RrJM", "RslWABX15", "e3CFiUFON"], optionTitles: ["Desktop", "iPad", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerQYzDtJTVF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})